import React from 'react';
import './about.css';

const About = () => (
  <main id="about">
    <p />
    <p>
      <br />
      <strong>
        <i>生子当如孙仲谋</i>
      </strong>
      <br />
      曹操的一声赞叹加上辛弃疾的一声叹息，留给后世父母们无限的想像，也承载着我们对孩子未来无尽的畅想和期盼。
    </p>

    <h2>教育初心</h2>
    <p>健康、乐观、自信，能从容面对自己的人生</p>

    <h2>传道、授业、解惑</h2>

      <p>2021.06.07</p>
      <strong>严老师反馈</strong>
      <ul>
          <li>小北自己总结了精心的原因：</li>
          <li>6道题粗心算错了</li>
          <li>3道题型理解错了</li>
          <li>3道审题错了</li>
          <li>1道怕没时间了</li>
          <li>想先选看似简单的着急来解决，苦于没想到办法，选了审题。办法是：题目审两遍以上</li>
      </ul>
      <br/>

      <p>2021.03.18</p>
    <strong>严老师反馈</strong>
    <ul>
      <li>孙仲谋爸爸，这是我们今天一年级口算比赛做的练习，是10分钟时间，但是孩子只做了57个，我看了一下主要是速度不够，还有扣空题不熟练。</li>
      <li>所以在课后还是要练一练速度，算减法可以让孩子背一背进位加，背熟了加法算减法自然就快了，还有扣空题的计算，我想了一些方法，在课上也说了。需要您关帮忙注一下</li>
      <li>练习：二十以内加减法、强化填空题型。背诵进位加。注意熟练度和速度。</li>
    </ul>
    <br/>

    <p>2021.03.09</p>
    <strong>一年级下学期目标</strong>
    <ul>
      <li>身体健康：早晚各一杯牛奶，每天一个鸡蛋，每周二次牛肉；保证每天9个小时睡眠；每周一次足球或蓝球</li>
      <li>保质保量，按时完成作业 - 先30分钟以内，下一阶段15分钟内</li>
      <li>添加其它任务原则，除作业外，其它学习事项，不得超过三个，超过三个后，加一个减二个</li>
    </ul>
    <br/>

    <p>2020.12.7</p>
    <strong>继续保持</strong>
    <ul>
      <li>学习热情足</li>
      <li>态度端正</li>
      <li>纪律性强</li>
    </ul>
    <br/>
    <strong>需要关注的点</strong>
    <ul>
      <li>字迹潦草</li>
      <li>审题粗心</li>
      <li>不够熟练</li>
    </ul>
    <br/>
    <strong>行动计划</strong>
    <ul>
      <li>合理安排时间"和小北一起做好时间规划</li>
      <li>加强练习：端正写字坐姿，加强田字格练习</li>
      <li>加强阅读，帮助识字：每日一练</li>
    </ul>
    <br/>

    <h2>
      在面对教育这件事情上，怎么样才能做到夫妻同心，齐力前行？又怎么样不偏离教育的初心，让咱们的孙仲谋健康、快乐的成长呢？
    </h2>
    <p>希望和大家一起学习、探讨。和孩子相互学习，共享宝贵的人生。</p>

    <h2>大家之言：</h2>
    <h4>教育的意义</h4>
    <p>
      《为学与做人》- 梁启超 -{' '}
      <i>教人不惑、不忧、不惧，实现用意志战胜欲望，顶天立地做一个人。</i>
    </p>
    <p>
      {' '}
      苏霍姆林斯基 -{' '}
      <i>
        我们的教育目的是为了把青少年培养成一个全面和谐发展的人，一个社会积极的进步参与者，而培养这样的人，需要德育、智育、体育、劳动教育和
        审美教育深度交织在一起，是一个统一的过程。
      </i>
    </p>

    <h4>怎么教育</h4>
    <p>
      《最近发展区》- 维果茨基 -{' '}
      <i>
        学生现有水平：学生独立活动时所能够解决的问题；可能发展水平：通过教学能够获得的潜力；二者的差异就是最近发展区，
        教学应该着眼于最近发展区，为学生提供有难度的内容来调动学生的积极性，同时也要发挥其潜能，超越最近发展区达到下一阶段。
      </i>
    </p>

    <h4>教育理念</h4>
    <p> 孔子: </p>
    <ul>
      <li>
        <i>因材施教</i>
      </li>
    </ul>
  </main>
);

export default About;
