import React from "react";

const Dashboard = () => (
  <main id="about">
    <h2>About this site</h2>
    <p>
      Dashboard...
    </p>
  </main>
);

export default Dashboard;